<template>
  <div class="auth-wrapper overflow-hidden">
    <div class="container-fluid no-pdd">
      <div class="row no-gutters align-items-center">
        <div class="col-lg-6 d-none d-lg-block no-pdd">
          <Left />
        </div>
        <div class="col-lg-6 no-pdd">
          <div class="auth-side sign-up-form">
            <AuthTop />
            <div class="sign-in-form">
              <form @submit.prevent @keyup.enter="Register(0)">
                <h4>Create Account</h4>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p v-else class="tag-line">It's easy and quick.</p>

                <div class="form-group">
                  <input
                    id="fname"
                    class="form-control"
                    v-model.trim="$v.details.fname.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.fname),
                    }"
                    trim
                    type="text"
                    required
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">First Name</span>
                  <div
                    v-if="!$v.details.fname.required"
                    class="invalid-tooltip"
                  >
                    First name is required
                  </div>
                  <div
                    v-if="!$v.details.fname.maxLength"
                    class="invalid-tooltip"
                  >
                    Maximum 18 characters allowed.
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="lname"
                    class="form-control"
                    v-model.trim="$v.details.lname.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.lname),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Last Name</span>
                  <div
                    v-if="!$v.details.lname.required"
                    class="invalid-tooltip"
                  >
                    Last name is required
                  </div>
                  <div
                    v-if="!$v.details.lname.maxLength"
                    class="invalid-tooltip"
                  >
                    Maximum 18 characters allowed.
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="email"
                    class="form-control"
                    v-model.trim="$v.details.email.$model"
                    spellcheck="false"
                    autocomplete="off"
                    ref="inputField" 
                    :class="{
                      'is-invalid': validationStatus($v.details.email),
                      filled: $v.details.email.$model.trim() !== '' // Add class when value exists
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                    :readonly="isEmailFetched"
                  />

                  <span class="placeholder">Email</span>
                  <div
                    v-if="!$v.details.email.required"
                    class="invalid-tooltip"
                  >
                    Email is required
                  </div>
                  <div v-if="!$v.details.email.email" class="invalid-tooltip">
                    Provide valid email
                  </div>
                </div>
                <div class="d-md-flex align-items-center">
                  <!-- <div class="form-group phone-code pr-md-2">
                    <b-form-select
                      v-model="details.mob_code"
                      :options="codeList"
                    >
                      <template #first></template>
                    </b-form-select>
                  </div> -->

                  <!-- <div class="form-group flex-grow-1">
                    <input
                      
                    />

                    <span class="placeholder">Mobile Number</span>
                    <div
                      v-if="!$v.details.mob.required"
                      class="invalid-tooltip"
                    >
                      Mobile number is required
                    </div>
                    <div v-if="!$v.details.mob.integer" class="invalid-tooltip">
                      Mobile number should be a number
                    </div>
                    <div
                      v-else-if="!$v.details.mob.minLength"
                      class="invalid-tooltip"
                    >
                      Minimum length should be 10
                    </div>
                  </div> -->
                </div>

                <div class="form-group">
                  <input
                    id="password"
                    class="form-control"
                    v-model.trim="$v.details.password.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.password),
                    }"
                    trim
                    required
                    :type="fieldTypes.password"
                    @focus="handleType"
                    @blur="handleType"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Password</span>
                  <i
                    v-html="btnText"
                    id="togglePassword"
                    @click="showPassword"
                  ></i>
                  <div
                    v-if="!$v.details.password.required"
                    class="invalid-tooltip"
                  >
                    Please enter password
                  </div>
                  <div
                    v-if="!$v.details.password.minLength"
                    class="invalid-tooltip"
                  >
                    Minimum 8 characters required
                  </div>
                  <div
                    v-if="!$v.details.password.maxLength"
                    class="invalid-tooltip"
                  >
                    Maximum 40 characters allowed.
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="confirm_password"
                    class="form-control"
                    v-model.trim="$v.details.password_confirmation.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus(
                        $v.details.password_confirmation
                      ),
                    }"
                    trim
                    required
                    :type="fieldTypes2.password"
                    @focus="handleType"
                    @blur="handleType"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Confirm Password</span>
                  <i
                    v-html="btnText2"
                    id="togglePassword"
                    @click="showConfirmPassword"
                  ></i>
                  <div
                    v-if="!$v.details.password_confirmation.required"
                    class="invalid-tooltip"
                  >
                    Enter confirm password
                  </div>
                  <div
                    v-if="!$v.details.password_confirmation.sameAsPassword"
                    class="invalid-tooltip"
                  >
                    Passwords do not match
                  </div>
                </div>
                <div>
                  <p class="term-line">
                    By clicking continue, you agree to our
                    <router-link to="/our-terms-and-conditions"
                      >Terms,
                    </router-link>
                    <router-link to="/our-privacy-policy">
                      Privacy Policy
                    </router-link>
                    and
                    <router-link to="/our-cookie-policy">
                      Cookie Policy
                    </router-link>
                  </p>
                </div>
                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabled == false"
                    type="button"
                    class="btn btn-primary w-100"
                    @click="Register(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Continue</span>
                  </button>
                  <button
                    v-else-if="this.isDisabled == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Continue</span>
                  </button>
                </div>
              </form>
              <button @click="login" class="auth-link mt-3">Sign In</button>
            </div>
            <AuthBottom />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  // integer,
} from "vuelidate/lib/validators";

import { mapGetters, mapActions } from "vuex";
import Left from "../../components/AuthLeft/Left.vue";
import AuthTop from "../../components/AuthLeft/AuthTop.vue";
import AuthBottom from "../../components/AuthLeft/AuthBottom.vue";
import axios from "axios";
export default {
  name: "Home",
  data: function() {
    return {
      lastSegment: "",
      fieldTypes: {
        password: "password",
      },
      fieldTypes2: {
        password: "password",
      },
      btnText: '<i class="fa fa-eye-slash"></i>',
      btnText2: '<i class="fa fa-eye-slash"></i>',
      errorMessage: null,
      isEmailFetched: false,
      isLoadingArray: [],
      details: {
        sendLinkUrl: "",
        fname: "",
        lname: "",
        email: "",
        // mob: "",
        // mob_code: "+1",

        password: "",
        password_confirmation: "",
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
      fontSize: 30,
      isDisabled: true,
    };
  },
  components: {
    Left,
    AuthTop,
    AuthBottom,
  },
  validations: {
    details: {
      // mob: { required, integer, minLength: minLength(10) },
      email: { required, email },
      fname: { required, maxLength: maxLength(18) },
      lname: { required, maxLength: maxLength(18) },
      password: { required, minLength: minLength(8), maxLength: maxLength(40) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.getLinkUrl(1);
    this.getDataUser();
    if (this.inputValue.trim() !== "") {
      this.$refs.inputField.classList.add("filled");
    }
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendRegisterRequest"]),
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    async getLinkUrl() {
  var linkUrl = window.location.pathname;
  var segment_array = linkUrl.split("/");
  this.lastSegment = segment_array.pop();

  if (this.lastSegment !== "register") {
    localStorage.setItem("linkUrl", this.lastSegment);
    localStorage.setItem("linkUrlActive", this.lastSegment);

    // Call API to get user data if the last segment is not "register"
    await this.getUnAuthorizedUser();
  }
},

async getUnAuthorizedUser() {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}getUnAuthorizedUser`, // API endpoint
      { c_id: this.lastSegment }, // Sending lastSegment as c_id
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data && response.data.data) {
      this.userData = response.data.data;
      this.details.email = response.data.data.target;
      this.isEmailFetched = !!this.details.email;
    } else {
      console.error("Failed to fetch user data:", response.data.message);
    }
  } catch (error) {
    console.error("Error fetching unauthorized user data:", error);
  }
},

    Register: function(index) {
    this.errorMessage = null;
    this.isDisabled = true;
    this.$v.$touch();
    if (this.$v.$pending || this.$v.$error) return;
    this.$set(this.isLoadingArray, index, true);
    this.details.sendLinkUrl = localStorage.getItem("linkUrl");

    try {
      this.sendRegisterRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          // Handle different scenarios based on API response
          if (response.data.data.user.email_verification == "1") {
            this.$router.push({ name: "Verify" });
          } else if (response.data.data.user.mobile_verification == "1") {
            this.$router.push({ name: "VerifyMobile" });
          } else {
            if (
              response.data.data.invite_count == null ||
              response.data.data.invite_count === 0
            ) {
              this.$router.push({ name: "Teams" });
            } else {
              localStorage.setItem("invite_count", response.data.data.invite_count);
              this.$router.push({ name: "PendingInvite" });
            }
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
        localStorage.setItem("addedCart", []);
      }).catch(() => {
        this.$set(this.isLoadingArray, index, false);
        this.$alert("Failed to send the email. Please try again later or contact support." );
      });
    } catch (emailError) {
      this.$set(this.isLoadingArray, index, false);
      this.errorMessage = "There was an issue sending the email. Please try again later.";
    }
  },
    errorNull: function() {
      this.errorMessage = null;
      if (this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    login: function() {
      this.$router.push({ name: "Login" });
    },

    showPassword: function() {
      if (this.fieldTypes.password === "password") {
        this.fieldTypes.password = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.fieldTypes.password = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    showConfirmPassword: function() {
      if (this.fieldTypes2.password === "password") {
        this.fieldTypes2.password = "text";
        this.btnText2 = "<i class='fa fa-eye'></i>";
      } else {
        this.fieldTypes2.password = "password";
        this.btnText2 = "<i class='fa fa-eye-slash'></i>";
      }
    },

    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty,
      };
    },

    displayDayError() {
      const day_format = document.querySelector(".day-format");
      day_format.style.display = "block";
      this.checkDay();
    },

    checkDay() {
      const dayPattern = /^[0-9]{1,2}$/;
      const day = document.querySelector("#day");
      const day_format = document.querySelector(".day-format");
      if (dayPattern.test(day.value)) {
        day.style.borderColor = "green";
        day_format.style.color = "green";
        day_format.style.display = "none";
      } else {
        day.style.borderColor = "red";
        day_format.style.color = "red";
      }
    },

    displayMonthText() {
      const month_format = document.querySelector(".month-format");
      month_format.style.display = "block";
      this.checkMonth();
    },

    checkMonth() {
      const monthPattern = /^[0-9]{1,2}$/;
      const month = document.querySelector("#month");
      const month_format = document.querySelector(".month-format");
      if (monthPattern.test(month.value)) {
        month.style.borderColor = "green";
        month_format.style.color = "green";
        month_format.style.display = "none";
      } else {
        month.style.borderColor = "red";
        month_format.style.color = "red";
      }
    },

    displayYearText() {
      const year_format = document.querySelector(".year-format");
      year_format.style.display = "block";
      this.checkYear();
    },

    checkYear() {
      const yearPattern = /^[0-9]{4}$/;
      const year = document.querySelector("#year");
      const year_format = document.querySelector(".year-format");
      if (yearPattern.test(year.value)) {
        year.style.borderColor = "green";
        year_format.style.color = "green";
        year_format.style.display = "none";
      } else {
        year.style.borderColor = "red";
        year_format.style.color = "red";
      }
    },
  },
};
</script>
